import React from "react";
import ReactDOM from "react-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";

class SalaryInput extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      period: 'a',
      salaryValue: 0.0,
      thirteenth: false
    };

    this.handleSalaryChange = this.handleSalaryChange.bind(this);
    this.handleThirteenChange = this.handleThirteenChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.salaryChangedEvent = this.salaryChangedEvent.bind(this);
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this.refs.salaryInput).focus();
  }

  render () {
    return (
      <div className="salary-input">
        <div className="clearfix text-xs-center">
          <div className="choose-annual">
            <select onChange={this.handlePeriodChange} value={this.state.period}>
              <option value="a">Annual</option>
              <option value="m">Monthly</option>
            </select> <br/>Salary
          </div>
          <div className="salary-container">
            <input type="text" placeholder="e.g 20000" onChange={this.handleSalaryChange} ref="salaryInput"/>
          </div>
        </div>
        <div className="text-xs-center">
          <label>
            <Toggle
              onChange={this.handleThirteenChange} defaultChecked={this.state.thirteenth}/>
            <span>13th month salary</span>
          </label>
        </div>

      </div>
    );
  };

  handlePeriodChange (e) {
    console.log(e);
    const newState = Object.assign({}, this.state, { 'period': e.target.value });
    this.setState(newState);
    console.log('changing period ' + e.target.value);
    window.ga('send', 'event', 'Setting', 'Period', e.target.value);
    this.props.salaryUpdated(newState);
  }

  handleThirteenChange (e) {
    const newState = Object.assign({}, this.state, { 'thirteenth': e.target.checked });
    this.setState(newState);

    this.props.salaryUpdated(newState);
    window.ga('send', 'event', 'Setting', 'Thirteenth', e.target.checked);
  }

  handleSalaryChange (e) {
    clearTimeout(this.timer);
    const newState = Object.assign({}, this.state, { 'salaryValue': parseFloat(e.target.value) });
    this.setState(newState);

    this.props.salaryUpdated(newState);

    this.timer = setTimeout(this.salaryChangedEvent, 3000);
  }

  salaryChangedEvent() {
    window.ga('send', 'event', 'Setting', 'salary entered', 'unlabeled', this.state.salaryValue);
  }

}

SalaryInput.styles = {};

export default SalaryInput;