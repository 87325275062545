import React from "react";
import Breakdown from "./Breakdown";

const Result = (props) => {

  let taxBreakdown;
  let e_breakdown='';
  if (typeof props.results.taxBreakdown === 'undefined') {
    taxBreakdown = [ ];
  } else {
    taxBreakdown = props.results.taxBreakdown;
    e_breakdown= <Breakdown visible={true} BreakdownEntries={taxBreakdown}/>;
  }




  return (
    <div>
      <div className="results-container">
        <div className="row">
          <div className="col-md-6">

            <h2>Annually</h2>
            <div className="result gross">
              <div>gross</div>
              &euro; {props.results.gross}</div>
            <div className="result tax">
              <div>tax</div>

              &euro; {props.results.tax}</div>
            <div className="result social">
              <div>social</div>

              &euro; {props.results.social}</div>
            <div className="result gesi">
              <div>NHIS (GESI)</div>

              &euro; {props.results.gesi}</div>
            <div className="result net">
              <div>net</div>

              &euro; {props.results.net}</div>
          </div>
          <div className="col-md-6">
            <h2>Monthly</h2>
            <div className="result gross">
              <div>gross</div>
              &euro; {props.results.grossMonthly}</div>
            <div className="result tax">
              <div>tax</div>
              &euro; {props.results.taxMonthly}</div>
            <div className="result social">
              <div>social</div>
              &euro; {props.results.socialMonthly}</div>
            <div className="result gesi">
              <div>NHIS (GESI)</div>
              &euro; {props.results.gesiMonthly}</div>
            <div className="result net">
              <div>net</div>
              &euro; {props.results.netMonthly}</div>
          </div>
        </div>
      </div>

      {e_breakdown}
    </div>
  );
};


Result.styles = {};

export default Result;