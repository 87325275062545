import React from "react";
import TaxCalculator from "../TaxCalculator";

class Breakdown extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };

    this.dropDown = this.dropDown.bind(this);
  }

  dropDown () {
    this.setState({
      open: !this.state.open
    });

  }

  render () {

    if (this.props.visible) {
      return (
        <div className="results-breakdown">
          <div className="row">
            <div className="col-md-12">
              <h2 onClick={this.dropDown}>Tax Breakdown <span
                className={'caret' + (this.state.open ? ' open' : ' closed')}
                aria-hidden="true"></span></h2>
            </div>
          </div>

          <div className={'row' + (this.state.open ? ' visible' : ' hidden') }>
            <div className="col-md-12">
              <table className="table table-striped table-bordered">
                <thead>
                <tr className="table-titles">
                  <th colSpan="3" className="tax-bracket">Tax Bracket</th>
                  <th>Tax Deducted</th>
                </tr>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Rate</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                  this.props.BreakdownEntries.map(function (row) {
                    let max = '';
                    if (row.rate.max === Number.MAX_SAFE_INTEGER) {
                      max = '-';
                    }
                    else {
                      max = '€'+ row.rate.max;
                    }



                    return (
                      <tr key={row.rate.min}>
                        <td>&euro;{row.rate.min}</td>
                        <td>{max}</td>
                        <td>{row.rate.pct}%</td>
                        <td>&euro;{TaxCalculator.formatMoney(row.taxDeducted)}</td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      );
    } else return ('');
  };


  static styles = {};

}

export default Breakdown;