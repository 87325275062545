import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const a = ReactDOM.render(<App />, document.getElementById('root'));

document.onreadystatechange = () => {
    if (document.readyState === 'complete') {
        document.getElementById('btn_contact').addEventListener('click', function () {
            a.contact(true);
        });
    }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
