import React, {Component} from "react";
import Result from "./components/Result.jsx";
import SalaryInput from "./components/SalaryInput.jsx";
import ContactForm from "./components/ContactForm.jsx";
import TaxCalculator from "./TaxCalculator";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

class App extends Component {

  constructor (props) {
    super(props);

    this.state = {
      contact: false,
      results: {
        gross: 0.0,
        net: 0.0,
        tax: 0.0,
        gesi: 0.0,
        social: 0.0,
        taxMonthly: 0.0,
        netMonthly: 0.0,
        socialMonthly: 0.0,
        gesiMonthly: 0.0,
        grossMonthly: 0.0
      }
    };

    this.salaryUpdated = this.salaryUpdated.bind(this);
    this.contact = this.contact.bind(this);
    this.complete = this.complete.bind(this);
  }

  contact (status) {
    const newState = Object.assign({}, this.state, { contact: status });
    this.setState(newState);
    window.ga('send','event','Contact Page','view','Contact Form');
  }


  salaryUpdated (inputState) {
    let salary;
    if (inputState.period === 'm')
      salary = inputState.salaryValue * (inputState.thirteenth ? 13 : 12);
    else salary = inputState.salaryValue;
    const results = TaxCalculator.calculateResults(salary, inputState.thirteenth);

    this.setState({
      results: results
    });

  }

  complete () {
    const newState = Object.assign({}, this.state, { contact: false });
    this.setState(newState);
  }

  render () {
    if (this.state.contact) {
      return (
          <div className="contactForm">
            <ContactForm onComplete={this.complete}/>
          </div>
      )
    } else {
      return (
          <div className="App">
            <SalaryInput salaryUpdated={this.salaryUpdated}/>
            <Result results={this.state.results}/>
          </div>
      );
    }
  }
}

export default App;
