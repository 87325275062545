import React from "react";
import axios from "axios";

export default class ContactForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      view: 'form'
    };

    this.sendMessage = this.sendMessage.bind(this);
    this.goBack = this.goBack.bind(this);
  }


  render () {

    if (this.state.view === 'form') {
      return (
        <div className="contactForm">
          <h1>Contact us</h1>
          <form onSubmit={this.sendMessage}>
            <div className="form-group">
              <label htmlFor="fld_email">Email</label>
              <input type="email" className="form-control" id="fld_email" aria-describedby="emailHelp"
                     placeholder="name@example.com" name="email" />
              <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="fld_name">Name</label>
              <input type="text" className="form-control" name="fullname" id="fld_name"
                     placeholder="Firstname Lastname"/>
            </div>
            <div className="form-group">
              <label htmlFor="fld_message">Message</label>
              <textarea className="form-control" id="fld_message" rows="3" name="message"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Send</button>
            <button type="button" className="btn btn-secondary btn_back" onClick={this.goBack}>Back</button>
          </form>

        </div>
      );
    }

    if (this.state.view === 'thanks') {
      return (
        <div className="contactForm">
          <h1>Thank you</h1>
          <p>We received your message and will get back to you asap.</p>
          <p>
            <button type="button" className="btn btn-primary" onClick={this.goBack}>Back</button>
          </p>
        </div>
      );
    }
  }

  goBack (e) {
    this.props.onComplete();
    this.setState({ view: 'form' });

  }

  sendMessage (e) {
    e.preventDefault();
    const data = new FormData(e.target);

    axios.post(
      '/send.php',
      data
    ).then((r) => {
      console.log('success');
      this.setState({
        view: 'thanks'
      });
    }).catch(function () {
      console.log('failed');
    });
  }
}
