export default class TaxCalculator {
  static taxRates = [
    { min: 0, max: 19500, pct: 0 },
    { min: 19501, max: 28000, pct: 20 },
    { min: 28001, max: 36300, pct: 25 },
    { min: 36301, max: 60000, pct: 30 },
    { min: 60000, max: Number.MAX_SAFE_INTEGER, pct: 35 }
  ];

  static gesiRate = 2.65;
  static socialInsuranceRate = 8.3;
  static socialInsuranceCap = 54396.0;

  static formatMoney (n) {
    // eslint-disable-next-line
    var
      c = 2,
      d = ".",
      t = ",",
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c), 10)),
      l = i.length,
      j = (l) > 3 ? l % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
  };

  static calculateResults (salary, hasThirteenth) {

    const yearlyGross = parseFloat(salary);

    // calculate tax
    let tax = 0.0;
    let untaxables = 0.0;
    let breakdown = [];

    // calculate gesi
    let gesi = yearlyGross * TaxCalculator.gesiRate / 100;

    // calculate social insurance
    let insurableAmount = (yearlyGross > TaxCalculator.socialInsuranceCap) ? TaxCalculator.socialInsuranceCap : yearlyGross;
    let social = insurableAmount * TaxCalculator.socialInsuranceRate / 100;

    untaxables += social;
    untaxables += gesi;

    let totalTaxableAmount = yearlyGross - untaxables;

    TaxCalculator.taxRates.forEach(function (rate) {
      if (totalTaxableAmount > rate.min) {
        let amountInTaxBracket = totalTaxableAmount;
        if (amountInTaxBracket > rate.max) amountInTaxBracket = rate.max;
        amountInTaxBracket -= rate.min;

        let taxDeducted = amountInTaxBracket * rate.pct / 100;
        tax += taxDeducted;
        breakdown.push({ rate: rate, taxDeducted: taxDeducted });
      }
    });

    const months = hasThirteenth ? 13 : 12;
console.log(gesi);
    return {
      gross: TaxCalculator.formatMoney(salary),
      tax: TaxCalculator.formatMoney(tax),
      social: TaxCalculator.formatMoney(social),
      gesi: TaxCalculator.formatMoney(gesi),
      net: TaxCalculator.formatMoney(salary - tax - social - gesi),
      grossMonthly: TaxCalculator.formatMoney(salary / months),
      taxMonthly: TaxCalculator.formatMoney(tax / months),
      socialMonthly: TaxCalculator.formatMoney(social / months),
      gesiMonthly: TaxCalculator.formatMoney(gesi / months),
      netMonthly: TaxCalculator.formatMoney((salary - tax - social - gesi) / months),
      taxBreakdown: breakdown
    }
  }
}
